import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import ReactMarkdown from "react-markdown/with-html"

/** 
 * Components
 */
import Stats from '@components/stats/stats';

const COUNT_UP_START = 0;
const COUNT_UP_DURATION = 3;

const HomeStats = (props) => {
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    return (
        <div className="home-stats">
            <Stats>
                <h3>{props.Title}</h3>
                <Row>
                {props.data.map((Stats, key) => {
                    const count = Stats.Value;
                    return <>
                    <Col md="4">
                        <h2 ref={ref}>
                            <CountUp
                                start={inView ? COUNT_UP_START : count }
                                end={Stats.Value}
                                delay={0}
                                duration={COUNT_UP_DURATION}
                            />{Stats.Value_Type}
                        </h2>
                        <ReactMarkdown source={Stats.Caption} escapeHtml={false}/>
                    </Col>
                    </>
                    })}                    
                </Row>
            </Stats>
        </div>
    )
}

export default HomeStats
