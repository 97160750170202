import React, { useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown/with-html"
import TextColumns from '@components/text-columns/text-columns';
import Intro from '@components/intro/intro';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import ImgBox from '@components/img-box/img-box';
import { Logo } from '@components/icon/icon';
import { openPopupWidget } from "react-calendly";
import Modal from 'react-bootstrap/Modal'
import MemberForm from "../components/forms/member-form"
import { Link } from "gatsby";

const SINGLE_TEAM = gql`
query GetTeamDetails ($URL: String!){
  teams (where:{URL: $URL}){
    id
    Name
    URL
    Designation
    Phone
    Email
    Calendly_Link
    About
    Image {
      url
    }
  }
}
`;



function TeamDetails(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [teamid, setteamid] = useState(false);
  const handleShow = (event) => {
    setteamid(event);
    setShow(true);
  }
  const { loading, error, data } = useQuery(SINGLE_TEAM, {
    variables: { URL: props.DetailURL }
  });
  const onClickCalendly = (url) => openPopupWidget({ url });

  if (loading) return (
    <div className="static-details gql-loading-screen">
        <Container>
          <Logo />
          <div className="gql-loading">Loading ...</div>
        </Container>
    </div>);

  return (
    <>
      {data.teams.map((node, key) => {
        return <>
          <div className="team-details team-details-view" data-aos="fade-up" data-aos-duration="1000">
            <TextColumns>
              <Col xl={{ span: 6, order: 1 }} md={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} xs={{ span: 12, order: 2 }} md="12" lg={6}>
                <div className="static-intro">
                  <Intro>
                    <div class="breadcrumbs">
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about/our-people/">Our People</Link></li>
                        <li><Link>{`${node.Name}/`}</Link></li>
                      </ul>
                    </div>

                    <div className="intro__text">
                      <h1>{node.Name}</h1>
                      <h3>{node.Designation}</h3>
                      <p><a href={`tel:${node.Phone}`} className="phone">{node.Phone}</a>  /  <a href="javascript:;" onClick={ () => handleShow(node.id)}> Email</a> 
                      {teamid && teamid == node.id &&
      <>
        <Modal
        show={show}
        centered={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w modal-form">

          <Modal.Header closeButton className="contact-close-btn memberPopup">
                <Modal.Title className="w-100">CONTACT - {node.Name}
            </Modal.Title>
              </Modal.Header>
        <Modal.Body>
        <MemberForm to_email_id={node.Email} staffname={node.Name} formName={`Contact Person`} />
        </Modal.Body>
      </Modal>
      </>
        }
                      {node.Calendly_Link !='' && node.Calendly_Link !=null &&
                      <>
                      / <a onClick={() => onClickCalendly(node.Calendly_Link)}  rel="noreferrer"   href="javascript:;">Video Call</a>
                      </>
                      }
                      </p>
                      <div className="team__bio">
                        <ReactMarkdown source={node.About} escapeHtml={false} />
                      </div>
                    </div>
                  </Intro>
                </div>
              </Col>

              <Col xl={{ span: 6, order: 2 }} md={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }} md="12" lg={6} className="pr-0 pl-0">
                <div className="content team-im-wrap">
                  <ImgBox image={`${node.Image.url}`}></ImgBox>
                </div>
              </Col>

            </TextColumns>
          </div>
        </>
      })}
    </>
  )
}

export default TeamDetails
