import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Col, Container, Row } from 'react-bootstrap';
import ReactMarkdown from "react-markdown/with-html"
import { useMatch } from "@reach/router"

/**
 * Assets
 */
import './styles/_index.scss';

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import ContactBox from '@components/contact-box/contact-box';
import Sidebar from '@components/sidebar/sidebar';
import NewsletterForm from '@components/forms/newsletter-form';
import Sticky from 'react-stickynode';

const ContactQuery = (props) => {
    const data = useStaticQuery(graphql`
        query {
        avatar: file(relativePath: { eq: "avatar.png" }) {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);
    const match = useMatch('/about/our-people/:item');

    return match ? (
        <empty></empty>
          ) : (
        <div className={`${props.layout == 'Static_Page'?' static-landing show-breadcrumbs':''}`}>
        <div className={`contact-query static-intro breadcrumbs-intro`}>
            <Container>
            <Row className="justify-content-between">
                <Col lg="7" className="left-cnt-sec">
                    <Breadcrumbs/>              
                    <h1>{props.Title}</h1>    
                    <div className="static-content-wrap">
                    <ReactMarkdown source={props.Content} escapeHtml={false}/>
                    </div>            
                    <div className="stickyremove"></div>
                </Col>
                <Col lg="4">
                <Sticky top={140} bottomBoundary='.stickyremove' enableTransforms={false}>
                            <Sidebar>
                            <NewsletterForm />
                            {props.data.map((data, key) => {
                            return <>
                                <ContactBox name={data.Name} phone={data.Phone} position={data.Designation} email={data.Email} link={data.Calendly_Link} linkText="Video Call" image={data.Image && data.Image.childImageSharp.fluid}  />
                            </>
                            })}
                            </Sidebar>
                            </Sticky>

                        </Col>
                    </Row>

            </Container>
        </div>
        </div>
    )
}

export default ContactQuery
