import React ,{useEffect} from "react"
import Img from 'gatsby-image';
import './styles/_valuation.scss'
import $ from "jquery";


import "aos/dist/aos.css";
import { Link,withPrefix } from "gatsby";
import ReactMarkdown from "react-markdown/with-html";

import LandingImg from "../../images/valuation-bg.png";
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import ChatForm from '@blocks/forms/chat-form';
import { useMatch } from "@reach/router"
import SEO from "@components/seo"

import Valuationform from '@components/forms/valution-form';
import '@blocks/forms/styles/_index.scss';

const Contact = (props) => {
  const valuationpage = useMatch("/value-my-property/:item/")
  //console.log('aaaaaaaaaaaa',valuationpage)
  const [showResults, setShowResults] = React.useState(false)
  const [showLanding, setshowLanding] = React.useState(true)
  useEffect(() => {
    if(valuationpage && valuationpage !==null) {
      setshowLanding(false)
      setShowResults(true)
    }
  });

  function onClick() {
    setShowResults(true)
    setshowLanding(false)
    if (typeof window !== "undefined") {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }

  }
    if (typeof window !== "undefined") {
  $(window).scroll(function () {
      var sticky = $('.sticky-header'),
          scroll = $(window).scrollTop();

      if (scroll >= 40) sticky.addClass('fixed');
      else sticky.removeClass('fixed');
  });
  }
  

  return (
  <>

      
      <div className="valuation-content">
        <div className="banner-img">
          <img src={LandingImg} alt="img" />
        </div>
        <Breadcrumbs />
        <Container>
        { showResults ? 
        <Row className="justify-content-md-center animate__animated animate__fadeInUp">
        <SEO title={`Property Valuation`} description={`Book your property valuation now. You can book a free property valuation with us for accurate property valuations in North London & Hertfordshire.`} />
        <h1 className="d-none">Property Valuation</h1>
        <Col md="12" lg={6}>
          <Valuationform />
          </Col>
          </Row>
           : null }
        {showLanding &&
        <>
          {/* <Breadcrumbs /> */}
          <div className="landing-head text-center">
            <h1>{props.Title}</h1>
            <ReactMarkdown source={props.Content} escapeHtml={false} />            
          </div>
          {/* property-wrap start */}
          <Row className="landing-items">
            <Col xl="6">
              <div className="landing-block text-center">                
                <Img fluid={props.LeftImg.childImageSharp.fluid} alt={`${props.LeftTitle} - Anthony Pepe`} />
                <h3>{props.LeftTitle}</h3>
                <ReactMarkdown source={props.LeftContent} escapeHtml={false} />            
                <Link to="/value-my-property/property-valuation/" className="btn btn-default openvaluationform" href="javascript:;">{props.LeftCta}</Link>
              </div>
            </Col>
            <Col xl="6">
              <div className="landing-block text-center">
                <Img fluid={props.RightImg.childImageSharp.fluid} alt={`${props.RightTitle} - Anthony Pepe`} />
                <h3>{props.RightTitle}</h3>
                <ReactMarkdown source={props.RightContent} escapeHtml={false} />            
                <a target="_blank" className="btn btn-default" href={props.RightLink}>{props.RightCta}</a>
              </div>
            </Col>
          </Row>
        </>
        }
          {/* property-wrap end */}
        </Container>
      </div>

    </>
    )
}

export default Contact
