import React, { useEffect, useState, useRef } from 'react';
import Video from '@components/video/video';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import { Link } from "gatsby";
import useDeviceDetect from '@blocks/detect-device/detect-device';
import AreaBasedAreaguide from './get-area-guide-by-area'
import PlayIcon from '@components/play-icon/play-icon';
import {
  isIOS,
  isMobileOnly,
  isBrowser,
  isMobile
} from "react-device-detect";
import ReactPlayer from 'react-player';

const SINGLE_AREA = gql`
query GetTeamDetails ($Prop_Postcode: String!){
    areaGuides (where:{Post_Code_contains:$Prop_Postcode}) {
      URL   
      Title
      Embed_Video_URL   
      Tile_Image {
        url
      }
    }
  
}
`;



function TeamDetails(props) {
  const { isMobile } = useDeviceDetect();
  const [play, setPlayvideo] = useState(true);
  const [mute, setMute] = useState(true);
  const vidRef = useRef(null);
  const [showVideo, setShowVideo] = React.useState(false);
  const [isPlay,setPlay] = React.useState(false);
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const playVideo = (video_index, video_id) => {
    setShowVideo(true)
  }
  const trackerVideo = (event) => {    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': url,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }

  const { loading, error, data } = useQuery(SINGLE_AREA, {
    variables: { Prop_Postcode: props.postcode }
  });
  if(data && data.areaGuides && data.areaGuides.length > 0) {
  if (loading) return <p>Loading ...</p>;
  return (
    <>
      {data && data.areaGuides && data.areaGuides.map((node, key) => {
        if(key == 0) {
        return <>
        <div className="guide-video">
        <div className="vide-wrapper video-box">
          <img src={node.Tile_Image.url}/>
            <div className="sep-play">
              <a href="javascript:;" onClick = { (e) => {playVideo()}}>
                <PlayIcon />
              </a>
            </div>
            {showVideo && 
              <>
              <div className="videoBTNClose">
              <a onClick={e => {setPlay(false);setShowVideo(false);}}></a>
              </div>
              <ReactPlayer autoplay ref={vidRef} onPlay={trackerVideo(node.Title)} url={node.Embed_Video_URL}  controls={true} autoplay={true} frameborder="0" muted={false} playsinline={true} playing={play} onEnded={() => { setShowVideo(false); }} width='100%' height='100%' />
              </>
            }
        </div>
        </div>
        Located in {props.address1}, {props.address2} 
		    <span><Link to={`/about/area-guide/${node && node.URL}/`}>Read {node.Title} Area Guide</Link></span>
        </>
        }
      })}
    </>
  )
} else {
return (
<AreaBasedAreaguide address1={props.address1} address2={props.address2} area={props.area} />
)
}
}

export default TeamDetails
