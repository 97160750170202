import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from "react-markdown/with-html"

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */
import ImgTextBox from '@components/img-text-box/img-text-box'
import ScrollAnimation from 'react-animate-on-scroll';

const HomeBoxes = (props) => {
    const data = useStaticQuery(graphql`
        query {
            img1: file(relativePath: { eq: "img1.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            img2: file(relativePath: { eq: "img2.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            allStrapiAllMenus {
              edges {
                node {
                  id
                  Label
                  Show_In_Burger_Menu
                  URL
                  main_parent {
                    URL
                    Show_In_Burger_Menu
                    Label
                  }
                  sub_parent {
                    URL
                    Label
                  }
                }
              }
            }  
        }
    `);
    return (
        <div className="home-boxes">
            <Container>
                <Row>
                    {props.data.map((Tiles, key) => {
                    return <>
                    {Tiles.Tile_Position == 'Left' &&
                    <Col md={12} lg={5}>
                        {Tiles.Tile_Image != null &&
                        <ImgTextBox image={Tiles.Tile_Image.childImageSharp.fluid} alt={`${Tiles.Title} - Anthony Pepe`}>
                            <h3>{Tiles.Title}</h3>                            
                            <ReactMarkdown source={Tiles.Content} escapeHtml={false}/>
                            {Tiles.Tile_URL != null &&
                            <>
                            {data.allStrapiAllMenus.edges.map(({node}, key) => {
                            return <>
                            {node.main_parent != null && node.URL == Tiles.Tile_URL.URL && node.sub_parent == null &&
                            <p><Link to={`/${node.main_parent.URL}/${Tiles.Tile_URL.URL}/`}>{Tiles.CTA_Label}</Link></p>
                            }
                            {node.main_parent != null && node.URL == Tiles.Tile_URL.URL && node.sub_parent != null &&
                            <p><Link to={`/${node.main_parent.URL}/${node.sub_parent.URL}/${Tiles.Tile_URL.URL}/`}>{Tiles.CTA_Label}</Link></p>
                            }
                            {node.main_parent == null && node.sub_parent == null && node.URL == Tiles.Tile_URL.URL && 
                            <p><Link to={`/${Tiles.Tile_URL.URL}/`}>{Tiles.CTA_Label}</Link></p>
                            }
                            </>
                            })}
                            </>
                            }
                        </ImgTextBox>
                        }
                    </Col>
                    }
                    {Tiles.Tile_Position == 'Right' &&
                    <Col className="rightboximg" md={12} lg={{
                        offset: 1,
                        span: 6
                    }}>
                    {Tiles.Tile_Image != null &&
                        <>
                        {Tiles.CTA_Label ==="iframe" ?
                            <div className="img-text-box">
                                <div className="image">
                                    <iframe src={Tiles.Embed_Video_URL}></iframe>
                                    <h3>{Tiles.Title}</h3> 
                                </div>       
                            </div>                    
                        :
                        <ImgTextBox image={Tiles.Tile_Image.childImageSharp.fluid} alt={`${Tiles.Title} - Anthony Pepe`}>
                                                   
                            <h3>{Tiles.Title}</h3>                            
                            <ReactMarkdown source={Tiles.Content} escapeHtml={false}/>
                             {Tiles.Tile_URL != null &&
                             <>
                            {data.allStrapiAllMenus.edges.map(({node}, key) => {
                            return <>
                            {node.main_parent != null && node.URL == Tiles.Tile_URL.URL && node.sub_parent == null &&
                            <p><Link to={`/${node.main_parent.URL}/${Tiles.Tile_URL.URL}/`}>{Tiles.CTA_Label}</Link></p>
                            }
                            {node.main_parent != null && node.URL == Tiles.Tile_URL.URL && node.sub_parent != null &&
                            <p><Link to={`/${node.main_parent.URL}/${node.sub_parent.URL}/${Tiles.Tile_URL.URL}/`}>{Tiles.CTA_Label}</Link></p>
                            }
                            {node.main_parent == null && node.sub_parent == null && node.URL == Tiles.Tile_URL.URL && 
                            <p><Link to={`/${Tiles.Tile_URL.URL}/`}>{Tiles.CTA_Label}</Link></p>
                            }
                            </>

                            })}
                            </>
                            }
                        </ImgTextBox>
                        }
                        </>
                    }
                    </Col>
                    }
                    </>
                    })}
                </Row>
            </Container>
        </div>
    )
}

export default HomeBoxes
