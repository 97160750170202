import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { useLocation } from "@reach/router"
import SendFriendForm from '@components/forms/sendfrnd-form';
import Modal from "react-bootstrap/Modal"
import $ from "jquery"
import "animate.css/animate.min.css";

/** 
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */

import { Heart, Share } from '@components/icon/icon';
import StickyNav from '@components/sticky-nav/sticky-nav'
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
}
    from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share"
import { SaveItem, useUserObjectState, useAuthState } from "@myaccount/website-service";

const PropertyFixedBar = (props) => {
    function gotohash(event) {
        var thishash = event;
        $('html, body').animate({
            scrollTop: $(thishash).offset().top-50
        }, 2000);
    }
    if ($(window).width() < 767) {
        $('.mobile-sticky-nav').insertBefore(".property-wrapper .property-top h1");
    }
    const location = useLocation();
    const shareUrl = location.href
    const [ref, inView] = useInView({
        threshold: 0
    });
    const [Shareicons, setShareicons] = React.useState(false);

    const [show, setShow] = useState(false);

    const { state, services } = useUserObjectState()
    const { services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()
  
    useEffect(() => {
      if (isAuthenticated) {
      // on page load get the users saved objects
          services.getUserobject({ type: "property", state:true })
      }
    }, [])
  

    const handleClose = () => setShow(false);
    const handleShow = (event) => {   
        setShow(true);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });   
    }

    const openShareicons = () => {
        setShareicons(!Shareicons)
    }
    const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });    
    }

    return (
        <div className="outer-holder active-bar" ref={ref}>
            <div className="property-fixed-bar">
                <Container>
                    <Row className="justify-content-between">
                        <Col className="d-none d-lg-block">
                            <h4>{props.address}</h4>
                            <div className="mobile-sticky-nav">
                            <StickyNav epc={props.epc} floorplan={props.floorplan} brochure={props.brochure} virtual_tour={props.virtual_tour} />
                            </div>
                        </Col>
                        <Col md="12" lg="auto">
                            
                            <div className="fixed-utils">
                                <SaveItem type="property" pid={props.id}>
                                    <i className="heart">
                                        <Heart />
                                    </i>
                                </SaveItem>
                                
                                <a href="javascript:;" className="white-btn d-none d-lg-flex" onClick={openShareicons}>
                                    <Share />
                                    {Shareicons &&
                                    <div className="hover-share animate__animated animate__fadeIn">
                                        <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareUrl} className="my-share-button facebook-share">
                                            <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                        </FacebookShareButton>
                                        <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareUrl} className="my-share-button twitter-share">
                                            <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                        </TwitterShareButton>
                                        <EmailShareButton  onClick={()=>handleShow('SendToFriend')} className="my-share-button email-share">
                                            <EmailIcon size={32} round={false} borderRadius={`10`} />
                                        </EmailShareButton>
                                        <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareUrl} className="my-share-button linked-share">
                                            <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                                        </LinkedinShareButton>

                                        <WhatsappShareButton onClick={()=>trackerShare('WhatsappShareButton')} url={shareUrl} className="my-share-button whatsapp-share">
                                            <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                                        </WhatsappShareButton>
                                    </div>
                                    }
                                </a>

                               

                                <a onClick={() => gotohash('#book-viewing')} href="javascript:;" className="btn">Book a Viewing</a>
                                <Link to="/contact/our-offices/" className="btn dark d-lg-none">Contact Us</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal
              id="share_modal_mobile"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
       		 dialogClassName="modal-90w modal-form"
            >
              <Modal.Header closeButton className="contact-close-btn memberPopup">
                <Modal.Title className="w-100">Share to a Friend</Modal.Title>
              </Modal.Header>
              <p className="col-12 content_r-l mt-3 mb-0 text-center modal-text">We work with very good suppliers and have access to special offers from the banks. Please leave your contact details and we will be in touch shortly. We can help.</p>
              <Modal.Body>
			  <SendFriendForm property_id={props.id} page_url={shareUrl} property_title={props.title ? props.title : ''} property_img={props.images ? props.images : ''} property_address={props.address_details ? props.address_details : ''} property_bedrooms={props.bedrooms ? props.bedrooms : ''} property_bathrooms={props.bathrooms ? props.bathrooms : ''} />

              </Modal.Body>
            </Modal>      


        </div>
        
    )
}

export default PropertyFixedBar
