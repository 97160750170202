import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Col, Container, Row } from 'react-bootstrap';
import Select from 'react-select';

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */
import useDeviceDetect from '@blocks/detect-device/detect-device';
import GetAreaGuide from './get-areaguide-property';
import LocRatingMap from '@components/map/loc-rating-map';
import { MapMarkerInfoBox } from '@components/map/map-marker';


const PropertyLocation = (props) => {
// Get Map Details
  let mapItems = [];
  let mapItem = {};
  mapItem['lat']  = props.latitude;        
  mapItem['lng']  = props.longitude;
  const mapMarkerImageShowFlag = 0;
  let mapItemHtml = MapMarkerInfoBox(props.mapaddress, mapMarkerImageShowFlag);
  mapItem['html'] = mapItemHtml;
  mapItems.push( mapItem );

    const { isMobile } = useDeviceDetect();
    const data = useStaticQuery(graphql`
        query {
        video: file(relativePath: { eq: "video2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2048) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);

    const styles = {
        control: styles => ({ 
            ...styles, 
            backgroundColor: null, 
            border: 0,
            outline: 0,
            boxShadow: 'none',
            fontSize: isMobile ? '2.4rem' : '1.4rem',
            color: '#5D9272',
            minHeight: isMobile ? '5.6rem' : '4.4rem',
            paddingLeft: isMobile ? '2.3rem' : '1.6rem'
        }),
        option: (styles) => ({
            ...styles,
            fontSize: isMobile ? '2.4rem' : '1.4rem',
        }),
        placeholder: () => null,
        dropdownIndicator: (styles) => ({
            ...styles,
            color: "#5D9272"
        }),
        indicatorsContainer: (styles) => ({
            ...styles,
            color: '#5D9272',
        }),
        indicatorSeparator: () => null,
        singleValue: styles => ({
            ...styles,
            color: '#5D9272',
        })
    }

    let AOS;
    useEffect(() => {
      const AOS = require("aos");
      AOS.init({
        once: true,
        disable: 'mobile'
      });
    }, [])



    return (
        <div className="property-location detail-office-map" data-aos="fade-up">
            <Container>
                <Row>
                    <Col lg="7" id="map" className="order-lg-1 map-holder">
                        <div style={{height: isMobile ? '300px' : '600px'}}>
                            <LocRatingMap zoom="15" searchtype={props.searchtype} data={mapItems} />
                        </div>
                    </Col>
                    <Col lg="5" id="video">
                        <small>
                         <GetAreaGuide address1={props.address.address1} address2={props.address.address2} officecrmid={props.officecrmid} postcode={props.postcode} area={props.area}/>
                        </small>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PropertyLocation
