import React, { useEffect, componentDidMount } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactMarkdown from "react-markdown/with-html"
import Video from '@components/video/video';
import TextColumns from '@components/text-columns/text-columns';
import Intro from '@components/intro/intro';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import LandingAccordion from '@components/landing-accordion/landing-accordion';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import ImgBox from '@components/img-box/img-box'
import { useStaticQuery, graphql, Link } from "gatsby";
import { Logo } from '@components/icon/icon';
import { Container } from 'react-bootstrap';

const SINGLE_TEAM = gql`
query GetTeamDetails ($CRM_Office_ID: String!){
  offices (where:{CRM_Office_ID:$CRM_Office_ID}) {
    Title
    URL
    Phone_Number
    CRM_Office_ID
  }
}
`;



function TeamDetails(props) {
  const { loading, error, data } = useQuery(SINGLE_TEAM, {
    variables: { CRM_Office_ID: props.officecrmid }
  });
  if (loading) return <p>Loading ...</p>;
    
  return (
    <>
      {data.offices.map((node, key) => {
        if(key == 0) {
        return <>
		    <h6>Call <span><Link to={`/contact/our-offices/${node.URL}/`}>{node.Title}</Link></span></h6>
        <h5><a href={`tel:${node.Phone_Number}`}><span>{node.Phone_Number}</span></a></h5>
        </>
        }
      })}
    </>
  )
}

export default TeamDetails
