import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown/with-html";
import { Link } from "gatsby";
import $ from 'jquery';
/**
 * Components
 */
import PositionBox from '@components/position-box/position-box';

/**
 * Assets
 */

import './styles/_index.scss';

const StaticPositions = (props) => {
    function gotohash(event) {
        var thishash = event;
        $('html, body').animate({
            scrollTop: $(thishash).offset().top-80
        }, 1000);
      }

    const data = useStaticQuery(graphql`
        query {
        image: file(relativePath: { eq: "grey-img.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);
  
    return (
        <div className="static-positions" id="jobopening">
            <Container>
                <h2>Current Positions</h2>
                <div className="position-slider">
                    <Row>
                    {props.data.edges.map(({node}, key) => {
                        var incekey =  key+1;
                        var delaying = incekey+'00';  
                        return <>
                        <Col data-aos-easing="ease-in-out" data-aos-offset="-50" data-aos="fade-up" data-aos-delay={delaying} md="6" lg="4">
                            <Link to={`${node.URL}/`}>
                            <PositionBox alt={node.Job_Opening+" - Anthony Pepe"} image={node.Tile_Image?.childImageSharp?.fluid}>
                                <h4>{node.Job_Opening}</h4>
                                <ReactMarkdown source={node.Caption} escapeHtml={false}/>
                            </PositionBox>
                            </Link>
                        </Col>
                        </>
                    })}
                    </Row>
                </div>
            </Container>
        <div className="property-fixed-bar footer-cta d-lg-none">
        <Container>
            <Row className="justify-content-between">
                <Col md="12" lg="auto">
                    <div className="fixed-utils">
                        <a href="#" onClick={() => gotohash('#jobopening')} className="btn dark d-lg-none">Open Positions</a>
                        <a href="#" onClick={() => gotohash('#section-bottom-form')} className="btn">Send Us Your CV</a>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>
        </div>
    )
}

export default StaticPositions
