import React, {useState, useEffect} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Row, Container, Col } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import axios from 'axios';
import PropertyTop from "@components/property-top/property-top";
import PropertyGallery from "@components/property-gallery/property-gallery";
import PropertyTextColumns from "@components/property-text-columns/property-text-columns";
import PropertyLocation from "@components/property-location/property-location";
import PropertyContact from "@components/property-contact/property-contact";
import PropertiesBoxes from "@components/properties-boxes/properties-boxes";
import Valuation from "@components/valuation/valuation";
import PropertyFixedBar from "@components/property-fixed-bar/property-fixed-bar";
import { Logo } from '@components/icon/icon';
import { useLocation } from "@reach/router";
import $ from 'jquery';
import { UserObjectStoreProvider, useUserObjectState, useAuthState, getUserobjectData } from "@myaccount/website-service";

const PROPERTY_DETAILS =  gql`
  query GetProperty ($id: String!){  
    properties(where:{id: $id}) {
      id
      title
      price
      price_qualifier
      images
      department
      search_type
      rent
      building
      address
      brochure
      display_address
      room_details
      office_crm_id
      description
      long_description
      crm_negotiator_id
      floorplan
      epc
      virtual_tour
      latitude
      longitude
      imagetransforms
      area
      negotiator
      accomadation_summary
      bedroom
      bathroom
      reception
    }
  }
`;
function PropertyDetails(props) {
const { loading, error, data } = useQuery(PROPERTY_DETAILS, {
    variables: { id: props.PropertyID }
  });
  
  const location = useLocation();
  const GlobalConfig = props.GlobalConfig
	const propertyUrl = location.href
  const [propItems, setPropItems] = useState([])
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {})
      if(data && data.length > 0){
        setPropItems(data)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const [userObjects, setUserObjects] = useState({});
  const { state: authState, services: authServices } = useAuthState()
  const isAuthenticated = authServices.isAuthenticated()

  useEffect(() => {
    setTimeout(function(){
      $('.header').removeClass('sticky-header');
    }, 3000);
    let url = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/Property-details?pid=" + props.PropertyID; 
    getitems(url)
    if (isAuthenticated) {
      const getUserObjects = async () => {
          try {
              const userObjects = await getUserobjectData()
              setUserObjects(userObjects.data.data)
          } catch (e) {
              console.log("e:", e)
          }
      }
      getUserObjects()
  }

  }, []);
  if (loading) return (
    <div className="static-details gql-loading-screen">
        <Container>
          <Logo />
          <div className="gql-loading">Loading ...</div>
        </Container>
    </div>);
  
  return (
    <Layout popularSearch='propertydetails' propertydetails={data.properties} footerDetails={GlobalConfig}>
      <UserObjectStoreProvider>
      <div className="property-wrapper d-flex flex-wrap">
      {data.properties.map((property, key) => {
        const metaTitle = property.title + ' in ' + property.display_address
        const metaDesc = 'Find the details of ' + property.title + ' in ' + property.display_address + ' with Anthony Pepe at ' + property.price + '. Contact Anthony Pepe Estate Agents and get assistance in finding your perfect property.'
        var acc_summ = property.accomadation_summary && property.accomadation_summary.toLocaleString();
        return <>
        <SEO image={property.images && property.images[0].url}title={metaTitle} description={metaDesc} />
        <PropertyTop address={property.display_address} 
        title={property.title} searchtype={property.search_type} rent={property.rent} price={property.price} pricequalifier={property.price_qualifier} officecrmid={property.office_crm_id} bedroom = {property.bedroom} bathroom = {property.bathroom} reception = {property.reception} id = {property.id} userObjects={userObjects}  />
        <PropertyGallery propertyimages={property.images} address={property.display_address} id={property.id} data={property}/>
        <PropertyFixedBar epc={property.epc} floorplan={property.floorplan} address={property.display_address} brochure={property.brochure} virtual_tour={property.virtual_tour} address_details={property.display_address} id={property.id} title={property.display_address} price={property.price} type={property.title} telephone={property.crm_negotiator_id?.Telephone} address_details={property.display_address} images={property.images[0].srcUrl} bedrooms={property.bedroom} bathrooms={property.bathroom} data={property}  />
        <PropertyTextColumns description={property.description} acc_summ={acc_summ} />
        <PropertyLocation searchtype={property.search_type === 'lettings'?false:true} videotour={property.virtual_tour} address={property.address} officecrmid={property.office_crm_id} latitude={property.latitude} longitude={property.longitude} mapaddress={property.display_address} postcode={property.address.postcode.split(" ")[0]} area={property.area.split(" ")[0]} />
        <PropertyContact negotiator={property.negotiator} propertyurl = {propertyUrl} officecrmid={property.office_crm_id}  />
        {propItems && propItems.length > 0 ? <PropertiesBoxes address={property.address} id={property.id} data={propItems.length > 2 ? propItems.slice(0, 2) : propItems} Title={`Similar Properties like ${property.address.address1}, London`} /> : ''}
        </>
      })}
      <Valuation Title={GlobalConfig.Footer_Box_Title} Content ={GlobalConfig.Footer_Box_Content} />
      {/* <Footer popularSearch='propertydetails' propertydetails={data.properties} footerDetails={GlobalConfig} /> */}
      </div>
      </UserObjectStoreProvider>
    </Layout>
  )
}
export default PropertyDetails
