/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Form, Button, Container, Row, Col } from "react-bootstrap"
import { filterNumber, numberFormat, pmt } from "./util"
import { defaultValues } from "./config"
import $ from 'jquery'
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from "gatsby";
import {calculateMonthlyPayment} from "../calculator"
// use project specific import here

// User project specific const
const durationOptions = [10, 15, 20, 30, 35]

// DO NOT DO ANY CHNAGES - START
const MortgageCalc = props => {

  const data = useStaticQuery(graphql`
        query {
        img3: file(relativePath: { eq: "calc-sidebar.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
  `);

  const {
    propertyPrice,
    depositAmount,
    loadDuration,
    interestRate,
    currency,
    defaultResult,
    pricePrefix,
    // instantResult,
    // durationSuffix,
    // yearSuffix,
    // yearSelect,
  } = props
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const prefix = pricePrefix ? currency : ""
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [purchasePrice, setPurchasePrice] = useState(
    prefix + numberFormat(propertyPrice)
  )
  const [deposit, setDeposit] = useState(prefix + numberFormat(depositAmount))
  const [duration, setDuration] = useState(loadDuration)
  const [interest, setInterest] = useState(interestRate)
  const [loan, setLoan] = useState("")
  const [result, setResult] = useState("")

  const handlePrice = event => {
    let val = filterNumber(event.target.value)
    setPurchasePrice(prefix + numberFormat(val))
    if (parseInt(filterNumber(deposit)) && parseInt(val)) {
      let loan = parseInt(val) - parseInt(filterNumber(deposit))
      setLoan(prefix + numberFormat(loan))
    } else {
      setLoan(prefix + 0)
    }
  }

  const handleDeposit = event => {
    let d = filterNumber(event.target.value)
    setDeposit(prefix + numberFormat(d))

    if (parseInt(filterNumber(purchasePrice)) && parseInt(d)) {
      let loan2 = parseInt(filterNumber(purchasePrice)) - parseInt(d)
      setLoan(prefix + numberFormat(loan2))
    } else {
      setLoan(prefix + 0)
    }
  }

  const handleDuration = event => {
    setDuration(filterNumber(event.target.value))
  }

  const handleInterest = event => {
    setInterest(event.target.value.replace(/[^\d.]/g, ""))
  }

  const handleLoan = event => {
    setLoan(prefix + numberFormat(filterNumber(event.target.value)))
  }

  const getResult = (interest, duration, loan) => {
    let result = -pmt(
      interest / 100 / 12,
      filterNumber(duration) * 12,
      filterNumber(loan),
      0,
      1
    )
    setResult(numberFormat(Math.round(result)))
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    var pval = Number($("input[name=purchase_price]").val().replace(/[^\d.-]/g, ''));
    var dval = Number($("input[name=deposit_available]").val().replace(/[^\d.-]/g, ''));
    if(dval > pval) {
      alert("Please enter deposit value less than property value");
      event.preventDefault()
      event.stopPropagation()
    }
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setShowerror(true)
      setValidated(true)
    } else {
      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        let loan =
          parseInt(filterNumber(purchasePrice)) -
          parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
        getResult(interest, duration, loan)
        //console.log('duration',parseInt(filterNumber(purchasePrice)),interest,parseInt(filterNumber(deposit)),duration)
        setMonthlyPayment(
          calculateMonthlyPayment(
            parseInt(filterNumber(purchasePrice)),
            parseFloat(interest),
            parseInt(filterNumber(deposit)),
            duration
          )
        );
      }
    }
  }

  useEffect(() => {

    if (defaultResult) {
      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        let loan =
          parseInt(filterNumber(purchasePrice)) -
          parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
      }
      getResult(interest, duration, loan)
    }
  }, [defaultResult, purchasePrice, deposit, loan, interest, duration, prefix])

  // DO NOT DO ANY CHNAGES - END

  

  var monthly_payment = Math.round(monthlyPayment);
  //console.log('aaaaaaaaaaaaaa', numberFormat(monthly_payment));

  return (
    
    <div className='static-text-columns calcpage mot-calc'>
    <Row>
    <Col lg={6}>
      <div className="left-img-calc">
        <Img fluid={data.img3.childImageSharp.fluid} alt="Estats Reviews - Anthony Pepe" />
      </div>
    </Col>
    <Col lg={6} className="form-calc-right">
    <div className="form mortgate-form stamp-duty-form">
    <div className="text-columns">
    <div className='container'>      {showerror && (
        <div className="alert-error">
          <p>Highlighted fields are required</p>
        </div>
      )}
      <Form
        name="MortgageCalc"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />
        <Form.Group controlId="purchase_price">
          <Form.Label>Property Value</Form.Label>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="purchase_price"
            value={purchasePrice}
            placeholder="Purchase Price"
            onChange={handlePrice}
          />
        </Form.Group>
        <Form.Group controlId="deposit">
          <Form.Label>Deposit</Form.Label>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="deposit_available"
            value={deposit}
            placeholder="Deposit Available"
            onChange={handleDeposit}
          />
        </Form.Group>
        <Form.Group controlId="duration">
          <Form.Label>Duration</Form.Label>
          <Form.Control
            as="select"
            className="form-control"
            required
            name="duration"
            value={duration}
            placeholder="Duration (Years)"
            onChange={handleDuration}
          >
            {durationOptions.map((value, key) => {
              return (
                <option value={value} key={key}>
                  {value} Years
                </option>
              )
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="interest_rate">
          <Form.Label>Interest Rate</Form.Label>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="interest_rate"
            value={interest}
            placeholder="Interest Rate (%)"
            onChange={handleInterest}
          />
        </Form.Group>
        <Form.Group controlId="loan_amount" className="d-none">
          <Form.Label>Loan Amount</Form.Label>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="loan_amount"
            value={loan}
            placeholder="Loan Amount"
            onChange={handleLoan}
            disabled="disabled"
          />
        </Form.Group>
        <Button type="submit" className="btn btn-pink btncalc">
          Calculate
        </Button>
      </Form>
      {result && (
        <div className="alert-success">
          <p>
            Monthly Repayment: {currency}
            {numberFormat(monthly_payment)}
          </p>
        </div>
      )}
    </div>
        </div>

    </div>
    </Col>
    </Row>
    </div>

  )
}

MortgageCalc.defaultProps = {
  propertyPrice: defaultValues.PROPERTY_VALUE,
  depositAmount: defaultValues.DEPOSIT,
  loadDuration: defaultValues.DURATION,
  interestRate: defaultValues.INTEREST,
  currency: process.env.CURRENCY
    ? process.env.CURRENCY
    : defaultValues.CURRENCY,
  defaultResult: defaultValues.DEFAULT_RESULT,
  pricePrefix: defaultValues.PRICE_PREFIX,
  // instantResult: defaultValues.INSTANT_RESULT,
  // durationSuffix: defaultValues.DURATION_SUFFIX,
  // yearSuffix: defaultValues.YEAR_SUFFIX,
  // yearSelect: defaultValues.YEAR_SELECT,
}

MortgageCalc.propTypes = {
  propertyPrice: PropTypes.any,
  depositAmount: PropTypes.any,
  loadDuration: PropTypes.any,
  interestRate: PropTypes.any,
  currency: PropTypes.string,
  defaultResult: PropTypes.any,
  pricePrefix: PropTypes.any,
  // instantResult: PropTypes.any,
  // durationSuffix: PropTypes.any,
  // yearSuffix: PropTypes.any,
  // yearSelect: PropTypes.any
}

export default MortgageCalc
